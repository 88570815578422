<p *ngIf="a11yAltText()" class="sr-only" [id]="a11yAltTextId">{{ a11yAltText() }}</p>
<button
  mat-button
  type="button"
  class="kitsune-menu"
  [ngClass]="{ 'kitsune-menu__outlined': outlined(), 'kitsune-menu__collapse': collapseOnMobile() }"
  [attr.aria-labelledby]="a11yAltText() ? a11yAltTextId : null"
  aria-haspopup="menu"
  (click)="open()"
  #menuButton
>
  <div class="flex items-center">
    <lib-svg
      *ngIf="icon()"
      [svg]="icon()"
      [class]="iconClass() + ' fill-white'"
      [ngClass]="{
        'sr-only sm:not-sr-only !hidden sm:!block': collapseOnMobile(),
        'fill-black': posMode(),
        'fill-white': !posMode(),
      }"
    ></lib-svg>
    <span
      class="kitsune-menu__label text-white font-bold text-sm xl:text-base line-clamp-1"
      [ngClass]="{ 'text-black': posMode(), 'text-white': !posMode() }"
    >
      {{ label() }}
    </span>
    <lib-svg
      svg="fontawesome/chevron-down"
      [ngClass]="{
        'sr-only sm:not-sr-only !hidden sm:!block': collapseOnMobile(),
        'fill-black': posMode(),
        'fill-white': !posMode(),
      }"
    ></lib-svg>
  </div>
</button>
<ng-template #menuContent>
  <ng-container *ngFor="let item of menuItems">
    <ng-container *ngIf="item.menuItemLink()?.routerLink || item.menuItemLink()?.rawUrl; else buttons">
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        <lib-svg
          *ngIf="item.icon()"
          [svg]="item.icon()"
          class="mr-3"
          [ngClass]="{
            'fill-black': posMode(),
            'fill-white': !posMode(),
          }"
        ></lib-svg>
        <a
          [ngClass]="{
            'mx-4': !item.icon(),
          }"
          *ngIf="item.menuItemLink().routerLink"
          mat-menu-item
          [routerLink]="item.menuItemLink().routerLink"
          [routerLinkActive]="item.menuItemLink().routerLinkActive ?? ''"
          [queryParams]="item.menuItemLink().queryParams"
          [queryParamsHandling]="item.menuItemLink().queryParamsHandling"
          [fragment]="item.menuItemLink().fragment"
        >
          <ng-template [ngTemplateOutlet]="item.template"></ng-template>
        </a>
        <a
          [ngClass]="{
            'mx-2': !item.icon(),
          }"
          *ngIf="item.menuItemLink().rawUrl"
          mat-menu-item
          [attr.href]="item.menuItemLink().rawUrl"
        >
          <ng-template [ngTemplateOutlet]="item.template"></ng-template>
        </a>
      </div>
    </ng-container>
    <ng-template #buttons>
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        <lib-svg
          *ngIf="item.icon()"
          [svg]="item.icon()"
          [ngClass]="{
            'fill-black': posMode(),
            'fill-white': !posMode(),
          }"
        ></lib-svg>
        <button
          mat-menu-item
          type="button"
          class="block w-full"
          (click)="close()"
          [ngClass]="{
            'mx-2': !item.icon(),
          }"
        >
          <ng-template [ngTemplateOutlet]="item.template"></ng-template>
        </button>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
