import { SliceClass } from 'interfaces';
import {
  NewLandingPageDocumentDataBodyLineCheckSlice,
  NewLandingPageDocumentDataBodyLineCheckSlicePrimary,
  prismicToHtmlString,
} from 'prismic';
import { AddressLookupFields } from 'utils';
import { LineCheckRepeatableItem } from './line-check.model';

type Slice = NewLandingPageDocumentDataBodyLineCheckSlicePrimary;

export class LineCheckSlice extends SliceClass {
  tabConfig: LineCheckTabConfig;
  addressLookupFields: AddressLookupFields;
  button_text: Slice['button_text'];
  back_button_text: Slice['back_button_text'];
  oto_id: Slice['oto_id'];
  sectionId: Slice['section_id'];
  promotion?: Slice['promotion'];
  product_type?: Slice['product_type'];
  special_product_type?: Slice['special_product_type'];
  type_of_page_shown_on?: Slice['type_of_page_shown_on'];
  other_products_available_when_ineligible?: string;
  product_when_eligible?: string;
  product_code?: Slice['product_code'];

  constructor(
    sliceData: NewLandingPageDocumentDataBodyLineCheckSlice,
    public lineCheckRepeatableItems: LineCheckRepeatableItem[]
  ) {
    super(sliceData);
    const { primary } = sliceData;

    this.button_text = primary.button_text;
    this.back_button_text = primary.back_button_text;
    this.oto_id = primary.oto_id;
    this.sectionId = primary.section_id;
    this.product_type = primary.product_type;
    this.special_product_type = primary.special_product_type;
    this.type_of_page_shown_on = primary.type_of_page_shown_on;
    this.other_products_available_when_ineligible = prismicToHtmlString(
      primary.other_products_available_when_ineligible
    );
    this.product_when_eligible = prismicToHtmlString(primary.product_when_eligible);
    this.promotion = primary.promotion;
    this.product_code = primary.product_code;
    this.addressLookupFields = {
      location_label: primary.location_label,
      location_placeholder: primary.location_placeholder,
      location_icon: primary.location_icon,
      street_label: primary.street_label,
      street_placeholder: primary.street_placeholder,
      street_icon: primary.street_icon,
      street_number_label: primary.street_number_label,
      street_number_placeholder: primary.street_number_placeholder,
      street_number_icon: primary.street_number_icon,
    };
    this.tabConfig = {
      featured_tab_title: primary.featured_tab_title,
      featured_tab_icon: primary.featured_tab_icon,
      local_tab_title: primary.local_tab_title,
      local_tab_icon: primary.local_tab_icon,
      global_tab_title: primary.global_tab_title,
      global_tab_icon: primary.global_tab_icon,
      prepaid_tab_title: primary.prepaid_tab_title,
      prepaid_tab_icon: primary.prepaid_tab_icon,
      all_tab_title: primary.all_tab_title,
      all_tab_icon: primary.all_tab_icon,
    };
  }
}

interface LineCheckTabConfig {
  [key: string]:
    | Slice['featured_tab_title']
    | Slice['featured_tab_icon']
    | Slice['local_tab_title']
    | Slice['local_tab_icon']
    | Slice['global_tab_title']
    | Slice['global_tab_icon']
    | Slice['prepaid_tab_title']
    | Slice['prepaid_tab_icon']
    | Slice['all_tab_title']
    | Slice['all_tab_icon'];
}
