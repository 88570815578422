import { SliceClass } from 'interfaces';
import {
  ContestFormDocument,
  ContestFormDocumentDataBodyTextInputSlice,
  NewLandingPageDocumentDataBodyContestFormEmbedSlice,
  prismicToHtmlString,
} from 'prismic';

export class ContestFormSlice extends SliceClass {
  title: string;
  caption: string;
  submit_text: string;
  showName: boolean;
  showBirthday: boolean;
  showPhoneNumber: boolean;
  additional_fields: ContestFormDocumentDataBodyTextInputSlice[];
  contest_name: string;
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodyContestFormEmbedSlice, formData: ContestFormDocument) {
    super(sliceData);
    this.title =
      typeof formData.data.title === 'string' ? formData.data.title : prismicToHtmlString(formData.data.title);
    this.caption =
      typeof formData.data.caption === 'string' ? formData.data.caption : prismicToHtmlString(formData.data.caption);
    this.submit_text =
      typeof formData.data.submit_text === 'string'
        ? formData.data.submit_text
        : prismicToHtmlString(formData.data.submit_text);
    this.showName = formData.data.show_name_input;
    this.showBirthday = formData.data.show_birthday_input;
    this.showPhoneNumber = formData.data.show_phone_number_input;
    this.additional_fields = formData.data.body;
    this.contest_name =
      typeof formData.data.contest_name === 'string'
        ? formData.data.contest_name
        : prismicToHtmlString(formData.data.contest_name);
    this.sectionId = sliceData.primary.section_id;
  }
}
