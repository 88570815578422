import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface menuEventData {
  template: TemplateRef<unknown>;
  positioningElement: ElementRef;
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _menuEvents: Subject<menuEventData> = new BehaviorSubject<menuEventData>(undefined);
  public readonly menuEvents: Observable<menuEventData> = this._menuEvents.asObservable();

  public open(data: menuEventData) {
    this._menuEvents.next(data);
  }

  public close() {
    this._menuEvents.next(undefined);
  }
}
