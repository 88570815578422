import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { sleep } from 'utils';
import { menuEventData, MenuService } from '../menu.service';

@Component({
  selector: 'lib-menu-global',
  templateUrl: './menu-global.component.html',
  styleUrls: ['./menu-global.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class MenuGlobalComponent {
  private menuService = inject(MenuService);
  private router = inject(Router);
  private _change = inject(ChangeDetectorRef);
  @ViewChild('template', { read: ViewContainerRef, static: false }) private template: ViewContainerRef;
  @ViewChild('menContainer') private menuElement: ElementRef<HTMLDivElement>;
  menuOpen = false;
  menuClosing = false;
  positioningElement: ElementRef<HTMLElement>;
  menuPosition: [number, number] = [0, 0];
  menuMinWidth = 95;
  menuId?: string;
  menuOnTop = false;

  @HostListener('window:resize', ['$event']) winResize() {
    this.updatePosition();
  }

  @HostListener('document:scroll', ['$event']) scroll() {
    this.updatePosition();
  }

  @HostListener('document:keydown', ['$event']) keyDown(event: KeyboardEvent) {
    if (!this.menuOpen) return;
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.arrowNavigate(1);
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.arrowNavigate(-1);
    }
    if (event.key === 'Tab') {
      this.positioningElement.nativeElement.focus();
      this.close();
    }
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor() {
    this.menuService.menuEvents.subscribe(async event => {
      if (!event) this.onClose();
      else this.onOpen(event);
    });
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationStart)) return;
      this.close();
    });
  }

  private async onOpen(event: menuEventData) {
    if (this.menuOpen || this.menuClosing) this.template.clear();
    this.template.createEmbeddedView(event.template);
    this.positioningElement = event.positioningElement;
    this.menuId = event.id;
    await this.updatePosition();
    const parent: HTMLDivElement = this.template.element.nativeElement.parentElement;
    const links = parent.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('role', 'menuitem');
      link.setAttribute('tabindex', '0');
      link.setAttribute('aria-disabled', 'false');
    });
    links[0]?.focus();
    this.menuOpen = true;
    this.menuClosing = false;
    this._change.markForCheck();
    await this.updatePosition();
  }

  async onClose() {
    this.menuOpen = false;
    this.menuClosing = true;
    await sleep(200); // allow closing animation to finish before clearing the contents
    if (!this.menuClosing) return;
    this.menuClosing = false;
    this.template.clear();
    this.positioningElement = undefined;
    this._change.markForCheck();
  }

  close() {
    this.menuService.close();
  }

  private arrowNavigate(change: number) {
    const parent: HTMLDivElement = this.template.element.nativeElement.parentElement;
    const links = Array.from(parent.querySelectorAll('a'));
    const link = <HTMLAnchorElement>parent.querySelector('a:focus');
    let index = links.indexOf(link);
    if (index === -1) index = 0;

    index += change;
    if (index < 0) index = links.length + index;
    if (index > links.length - 1) index -= links.length;

    links.forEach(l => l.classList.remove('focused'));
    links[index]?.classList.add('focused');
    links[index]?.focus();
  }

  async updatePosition() {
    if (!this.positioningElement) return;
    await sleep(0); // needed to give menuElement time to render (so that we know its height)
    const bounds = this.positioningElement.nativeElement.getBoundingClientRect();
    this.menuPosition = this.calcPosition(bounds) || [0, 0];
    this.menuMinWidth = this.calcMinWidth(bounds) || 95;
    this._change.markForCheck();
  }

  calcMinWidth(bounds: DOMRect): number {
    return bounds.right - bounds.left;
  }

  calcPosition(bounds: DOMRect): [number, number] {
    let left = bounds.left;
    const menuBounds = this.menuElement.nativeElement.getBoundingClientRect();
    let top = bounds.top + bounds.height;
    this.menuOnTop = false;

    if (top + menuBounds.height > document.scrollingElement.scrollHeight - document.scrollingElement.scrollTop) {
      top -= menuBounds.height + bounds.height;
      this.menuOnTop = true;
    }
    if (left + menuBounds.width > document.scrollingElement.scrollWidth) {
      left -= menuBounds.width - bounds.width;
    }

    return [left, top];
  }
}
