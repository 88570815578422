import { SafeHtml } from '@angular/platform-browser';
import { LinkField, LinkToMediaField } from '@prismicio/client';
import { MenuItem } from 'interfaces';
import { Offer } from 'prismic';
import { ProductListItem } from 'product';

export class MenuItemWithDropdown extends MenuItem {
  offer: Offer;
  secondLevelEntries: SecondLevelMenuItem[];
  product: ProductListItem;
}

export interface SecondLevelMenuItem {
  title: string;
  text: SafeHtml;
  image: LinkToMediaField;
  cta: string;
  link: LinkField;
}
