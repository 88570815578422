import { ImageFieldImage, LinkField } from '@prismicio/client';
import { Image, SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyBenefitsListSlice, prismicToImage } from 'prismic';

export class BenefitsListSlice extends SliceClass {
  button_text: string;

  constructor(
    sliceData: NewLandingPageDocumentDataBodyBenefitsListSlice,
    public title: string,
    public button_link: LinkField,
    public items: BenefitsListItem[],
    public sectionId: string
  ) {
    super(sliceData);
    this.button_text = sliceData.primary.button_text;
  }
}

export class BenefitsListItem {
  icon: Image;

  constructor(
    public benefitIcon: ImageFieldImage,
    public title: string,
    public description: string
  ) {
    if (benefitIcon?.url) {
      this.icon = prismicToImage(benefitIcon);
    }
  }
}
