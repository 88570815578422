import { PrismicSlice, Redirect, RedirectResponse, SliceClass } from 'interfaces';

export class RedirectsSlice extends SliceClass {
  redirects: Redirect[];

  constructor(sliceData: PrismicSlice) {
    super(sliceData);
    this.redirects = sliceData.items.map(item => new Redirect(item as RedirectResponse));
  }
}
