import { inject, Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
@Injectable({
  providedIn: 'root',
})
export class MainNavigationResolver {
  private navService = inject(NavigationService);

  async resolve() {
    const [nav, phone, logo, themeColor] = await Promise.all([
      this.navService.getMainNav(),
      this.navService.getPhoneNumber(),
      this.navService.getLogo(),
      this.navService.getThemeColor(),
    ]);

    return { nav, phone, logo, themeColor };
  }
}
