import { SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyCenteredAccordionSlice } from 'prismic';
import { AccordionItem } from '../../../shared/interfaces/accordian/accordian-item';

export class CenteredAccordionSlice extends SliceClass {
  title: string;
  items: AccordionItem[];
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodyCenteredAccordionSlice, title: string, items: AccordionItem[]) {
    super(sliceData);
    this.title = title;
    this.items = items;
    this.sectionId = sliceData.primary.section_id;
  }
}
