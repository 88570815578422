import { LinkField } from '@prismicio/client';
import { SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyHeroAreaSlice, Offer } from 'prismic';

export class HeroAreaSlice extends SliceClass {
  background_url?: string;
  image: string;
  image_alt: string;
  button_icon?: string;
  button_text?: string;
  offer_display?: string;

  constructor(
    sliceData: NewLandingPageDocumentDataBodyHeroAreaSlice,
    public offer?: Offer,
    public secondaryOffer?: Offer,
    public button_link?: LinkField,
    public title?: string,
    public description?: string
  ) {
    super(sliceData);

    const primary = sliceData.primary;
    this.offer_display = primary.offer_display;
    this.background_url = primary.background_image?.url;
    if (primary.image) {
      this.image = primary.image.url?.replace('.gif?auto=compress,format', '.gif');
    }
    if (primary.image_alt) {
      this.image_alt = primary.image_alt;
    }
    this.button_icon = primary.button_icon;
    this.button_text = primary.button_text;
    this.button_link = primary.button_link;
  }
}
