import { LinkField } from '@prismicio/client';
import { SliceClass } from 'interfaces';
import {
  FooterDocumentDataBodyBottomLinksAreaSlice,
  FooterDocumentDataBodyCtaAreaSlice,
  FooterDocumentDataBodyLinksAreaSlice,
} from 'prismic';

export class FooterLink {
  constructor(
    public title: string,
    public url: LinkField
  ) {}
}

export class FooterLinkSection {
  constructor(
    public title: string,
    public linkItems: Array<FooterLink>
  ) {}
}

export class FooterBottomLinkSelection {
  constructor(public linkItems: Array<FooterLink>) {}
}

export class FooterLinksAreaSlice extends SliceClass {
  constructor(
    sliceData: FooterDocumentDataBodyLinksAreaSlice,
    public linkSections: Array<FooterLinkSection>
  ) {
    super(sliceData);
  }
}
export class FooterBottomLinksAreaSlice extends SliceClass {
  company_name: string;
  copyright_text: string;

  constructor(
    sliceData: FooterDocumentDataBodyBottomLinksAreaSlice,
    public linkSections: Array<FooterBottomLinkSelection>
  ) {
    super(sliceData);
    this.company_name = sliceData.primary.company_name;
    this.copyright_text = sliceData.primary.copyright_text;
  }
}

export class FooterLinkItem {
  constructor(
    public title: string,
    public description: string,
    public icon: string,
    public url: LinkField
  ) {}
}

export class FooterCTAAreaSlice extends SliceClass {
  constructor(
    sliceData: FooterDocumentDataBodyCtaAreaSlice,
    public linkItems: Array<FooterLinkItem>
  ) {
    super(sliceData);
  }
}

export class Footer {
  constructor(public slices: SliceClass[]) {}
}
