import { SliceClass } from 'interfaces';
import {
  NewLandingPageDocumentDataBodyProductsSlice,
  NewLandingPageDocumentDataBodyProductsSlicePrimary,
} from 'prismic';
import { ProductListItem } from 'product';

type Slice = NewLandingPageDocumentDataBodyProductsSlicePrimary;

export class ProductsSlice extends SliceClass {
  products: ProductListItem[];
  orderType = 'new';
  mobileStartPosition: Slice['mobile_start_position'];
  tabConfig: TabConfig;
  sectionId: Slice['section_id'];

  constructor(
    sliceData: NewLandingPageDocumentDataBodyProductsSlice,
    products: ProductListItem[],
    public title: string,
    public tabs: boolean
  ) {
    super(sliceData);
    this.products = products;
    this.sectionId = sliceData.primary.section_id;
    const primary = sliceData.primary;

    this.mobileStartPosition = primary.mobile_start_position;
    this.tabConfig = {
      featured_tab_title: primary.featured_tab_title,
      featured_tab_icon: primary.featured_tab_icon,
      local_tab_title: primary.local_tab_title,
      local_tab_icon: primary.local_tab_icon,
      global_tab_title: primary.global_tab_title,
      global_tab_icon: primary.global_tab_icon,
      prepaid_tab_title: primary.prepaid_tab_title,
      prepaid_tab_icon: primary.prepaid_tab_icon,
      all_tab_title: primary.all_tab_title,
      all_tab_icon: primary.all_tab_icon,
    };
  }
}

export interface TabConfig {
  featured_tab_title: Slice['featured_tab_title'];
  featured_tab_icon: Slice['featured_tab_icon'];
  local_tab_title: Slice['local_tab_title'];
  local_tab_icon: Slice['local_tab_icon'];
  global_tab_title: Slice['global_tab_title'];
  global_tab_icon: Slice['global_tab_icon'];
  prepaid_tab_title: Slice['prepaid_tab_title'];
  prepaid_tab_icon: Slice['prepaid_tab_icon'];
  all_tab_title: Slice['all_tab_title'];
  all_tab_icon: Slice['all_tab_icon'];
}
