import { PrismicSlice, SliceClass } from 'interfaces';

export class CustomContentSlice extends SliceClass {
  content: string;

  constructor(sliceData: PrismicSlice, content: string) {
    super(sliceData);
    this.content = content;
  }
}
