import { SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodySignUpFormSlice } from 'prismic';

export class SignUpFormSlice extends SliceClass {
  form_title: string;
  form_name_tracking: string;
  firstName_label: string;
  firstName_placeholder: string;
  firstName_icon: string;
  firstName_required: boolean;
  lastName_label: string;
  lastName_placeholder: string;
  lastName_icon: string;
  lastName_required: boolean;
  gender_label: string;
  gender_placeholder: string;
  gender_icon: string;
  gender_required: boolean;
  email_label: string;
  email_placeholder: string;
  email_icon: string;
  email_required: boolean;
  birthday_label: string;
  birthday_placeholder: string;
  birthday_icon: string;
  birthday_required: boolean;
  button_label: string;
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodySignUpFormSlice) {
    super(sliceData);
    this.form_title = sliceData.primary.form_title;
    this.form_name_tracking = sliceData.primary.form_name_tracking;
    this.firstName_label = sliceData.primary.first_name_label;
    this.firstName_placeholder = sliceData.primary.first_name_placeholder;
    this.firstName_icon = sliceData.primary.first_name_icon;
    this.firstName_required = sliceData.primary.first_name_required;
    this.lastName_label = sliceData.primary.last_name_label;
    this.lastName_placeholder = sliceData.primary.last_name_placeholder;
    this.lastName_icon = sliceData.primary.last_name_icon;
    this.lastName_required = sliceData.primary.last_name_required;
    this.gender_label = sliceData.primary.gender_label;
    this.gender_placeholder = sliceData.primary.gender_placeholder;
    this.gender_icon = sliceData.primary.gender_icon;
    this.gender_required = sliceData.primary.gender_required;
    this.email_label = sliceData.primary.email_label;
    this.email_placeholder = sliceData.primary.email_placeholder;
    this.email_icon = sliceData.primary.email_icon;
    this.email_required = sliceData.primary.email_required;
    this.birthday_label = sliceData.primary.birthday_label;
    this.birthday_placeholder = sliceData.primary.birthday_placeholder;
    this.birthday_icon = sliceData.primary.birthday_icon;
    this.birthday_required = sliceData.primary.birthday_required;
    this.button_label = sliceData.primary.button_label;
    this.sectionId = sliceData.primary.section_id;
  }
}
