import { Modal, SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBody1ModalsSlice } from 'prismic';

export class ModalsSlice extends SliceClass {
  modals: Modal[];

  constructor(sliceData: NewLandingPageDocumentDataBody1ModalsSlice, modals: Modal[]) {
    super(sliceData);
    this.modals = modals;
  }
}
