import { PrismicSlice, SliceClass } from 'interfaces';
import { ContentBox } from 'prismic';

export class ContentBoxesSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public title: string,
    public items: Array<ContentBox>,
    public sectionId: string
  ) {
    super(sliceData);
  }
}
