import { SliceClass } from 'interfaces';
import {
  checkForEmptyPrismicHtml,
  NewLandingPageDocumentDataBodyTariffTableSlice,
  NewLandingPageDocumentDataBodyTariffTableSliceItem,
} from 'prismic';
import { TableHeaders } from './tariff-table.model';

export class TariffTableSlice extends SliceClass {
  tariff_allowances: TariffAllowance[] = [];
  tableHeaders: TableHeaders;
  currentUrl: string;
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodyTariffTableSlice, currentUrl: string) {
    super(sliceData);
    const primary = sliceData.primary;
    this.currentUrl = currentUrl;
    this.tableHeaders = {
      principal_header: primary.principal_header,
      switzerland_header: checkForEmptyPrismicHtml(primary.switzerland_header),
      switzerland_icon: primary.switzerland_icon,
      roaming_header: checkForEmptyPrismicHtml(primary.roaming_header),
      roaming_icon: primary.roaming_icon,
      tariff_header_desktop: checkForEmptyPrismicHtml(primary.tariff_header_desktop),
      tariff_header_mobile: checkForEmptyPrismicHtml(primary.tariff_header_mobile),
      tariff_icon: primary.tariff_icon,
    };
    this.tariff_allowances = sliceData.items.map((item: NewLandingPageDocumentDataBodyTariffTableSliceItem) => {
      return {
        ...item,
        title: checkForEmptyPrismicHtml(item.title),
      };
    }) as TariffAllowance[];
    this.sectionId = primary.section_id;
  }
}
export interface TariffAllowance {
  tariff_field: string;
  title: string;
  switzerland_allowance_unlimited: boolean;
  switzerland_allowance: string;
  switzerland_allowance_subtext: string;
  roaming_allowance_unlimited: boolean;
  roaming_allowance: string;
  roaming_allowance_subtext: string;
}
