import { LinkField } from '@prismicio/client';
import { ImageSet, SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyImageTextSlice } from 'prismic';

export class ImageTextSlice extends SliceClass {
  title: string;
  text: string;
  button_text: string;
  image_position_desktop: string;
  images: ImageSet;
  button_link: LinkField;
  sectionId: string;

  constructor(
    sliceData: NewLandingPageDocumentDataBodyImageTextSlice,
    title: string,
    text: string,
    images: ImageSet,
    button_link: LinkField
  ) {
    super(sliceData);
    this.title = title;
    this.text = text;
    this.button_text = sliceData.primary.button_text;
    this.image_position_desktop = sliceData.primary.image_position_desktop;
    this.images = images;
    this.button_link = button_link;
    this.sectionId = sliceData.primary.section_id;
  }
}
