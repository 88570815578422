import { ImageFieldImage, LinkField } from '@prismicio/client';
import { Image, PrismicSlice, SliceClass } from 'interfaces';
import { prismicToImage } from 'prismic';

export class CardListSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public title: string,
    public items: CardListItem[],
    public sectionId: string
  ) {
    super(sliceData);
  }
}

export class CardListItem {
  icon: Image;

  constructor(
    public categoryIcon: ImageFieldImage,
    public title: string,
    public description: string,
    public button_text: string,
    public button_link: LinkField
  ) {
    if (categoryIcon?.url) {
      this.icon = prismicToImage(categoryIcon);
    }
  }
}
