import { PrismicSlice, SliceClass } from 'interfaces';
import { Offer } from 'prismic';

export class OfferBoxesSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public offers: Offer[],
    public displaySize: string,
    public title?: string,
    public sectionId?: string
  ) {
    super(sliceData);
  }
}
