import { SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyTableEmbedSlice } from 'prismic';
import { TableData, TableDataColumn, TableDataRow } from './table.model';

export class TableSlice extends SliceClass implements TableData {
  title: string;
  caption: string;
  columns: TableDataColumn[];
  rows: TableDataRow[];
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodyTableEmbedSlice, tableData: TableData) {
    super(sliceData);

    this.title = tableData.title as string;
    this.caption = tableData.caption as string;
    this.columns = tableData.columns;
    this.rows = tableData.rows;
    this.sectionId = sliceData.primary.section_id;
  }
}
