import { LinkField } from '@prismicio/client';
import { PrismicSlice, SliceClass } from 'interfaces';

export class PhoneNumberSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public phone_number: string,
    public description: string,
    public icon: string,
    public link: LinkField,
    public alternative_text: string,
    public sectionId: string
  ) {
    super(sliceData);
  }
}
