import { inject, Injectable } from '@angular/core';
import { FilledContentRelationshipField, PrismicDocument, RichTextField, RTTextNode, Slice } from '@prismicio/client';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { UserService } from 'auth-data-access';
import { ImageSet, LoggedInStatus, Modal, Promotion, RawLink, SliceClass } from 'interfaces';
import { OfferCountDownSlice, OfferCtaSlice, OfferStickerSlice } from 'offer-box';
import {
  CmsService,
  ContentBox,
  ContestFormDocument,
  FooterDocumentDataBodyBottomLinksAreaSlice,
  FooterDocumentDataBodyBottomLinksAreaSliceItem,
  FooterDocumentDataBodyCtaAreaSlice,
  FooterDocumentDataBodyCtaAreaSliceItem,
  FooterDocumentDataBodyLinksAreaSlice,
  FooterDocumentDataBodyLinksAreaSliceItem,
  LandingPage,
  LandingPageDocumentDataBodyRedirectSlice,
  ModalKitsuneDocument,
  ModalKitsuneDocumentDataBodyCustomContentSlice,
  ModalKitsuneDocumentDataBodyImageKitsuneSlice,
  NewLandingPageDocumentDataBody1ModalsSlice,
  NewLandingPageDocumentDataBodyBenefitsListSlice,
  NewLandingPageDocumentDataBodyBenefitsListSliceItem,
  NewLandingPageDocumentDataBodyCardListSlice,
  NewLandingPageDocumentDataBodyCardListSliceItem,
  NewLandingPageDocumentDataBodyCenteredAccordionSlice,
  NewLandingPageDocumentDataBodyContentBoxesSlice,
  NewLandingPageDocumentDataBodyContentBoxesSliceItem,
  NewLandingPageDocumentDataBodyContestFormEmbedSlice,
  NewLandingPageDocumentDataBodyCustomContentSlice,
  NewLandingPageDocumentDataBodyHeroAreaSlice,
  NewLandingPageDocumentDataBodyImageTextSlice,
  NewLandingPageDocumentDataBodyLandingPagePartialSlice,
  NewLandingPageDocumentDataBodyLineCheckSlice,
  NewLandingPageDocumentDataBodyOfferBoxesSlice,
  NewLandingPageDocumentDataBodyOfferBoxesSliceItem,
  NewLandingPageDocumentDataBodyPhoneNumberSliceSlice,
  NewLandingPageDocumentDataBodyProductDetailOverviewSlice,
  NewLandingPageDocumentDataBodyProductsSlice,
  NewLandingPageDocumentDataBodyProductsSliceItem,
  NewLandingPageDocumentDataBodySignUpFormSlice,
  NewLandingPageDocumentDataBodyTableEmbedSlice,
  NewLandingPageDocumentDataBodyTariffTableSlice,
  Offer,
  OfferDocument,
  OfferDocumentDataBodyCountDownSlice,
  OfferDocumentDataBodyCountDownSliceItem,
  OfferDocumentDataBodyOfferCtaSlice,
  OfferDocumentDataBodySquircleSlice,
  prismicToHtmlString,
  ProductBoxDocument,
} from 'prismic';
import { PCProduct, ProductListItem, ProductService } from 'product';
import { BrowserService, createLinkFromString, StateService, StorageKeys } from 'utils';
import {
  Footer,
  FooterBottomLinksAreaSlice,
  FooterBottomLinkSelection,
  FooterCTAAreaSlice,
  FooterLink,
  FooterLinkItem,
  FooterLinksAreaSlice,
  FooterLinkSection,
} from '../classes/footer.class';
import { translationObj } from '../interfaces/translation.interface';
import { BenefitsListItem, BenefitsListSlice } from '../landing-pages/slices/benefits-list/benefits-list.slice';
import { CardListItem, CardListSlice } from '../landing-pages/slices/card-list/card-lists.slice';
import { CenteredAccordionSlice } from '../landing-pages/slices/centered-accordion/centered-accordion.slice';
import { ContentBoxesSlice } from '../landing-pages/slices/content-boxes/content-boxes.slice';
import { ContestFormSlice } from '../landing-pages/slices/contest-form/contest-form.slice';
import { CustomContentSlice } from '../landing-pages/slices/custom-content/custom-content.slice';
import { HeroAreaSlice } from '../landing-pages/slices/hero-area/hero-area.slice';
import { ImageTextSlice } from '../landing-pages/slices/image-text/image-text.slice';
import { LineCheckRepeatableItem } from '../landing-pages/slices/line-check/line-check.model';
import { LineCheckSlice } from '../landing-pages/slices/line-check/line-check.slice';
import { ModalsSlice } from '../landing-pages/slices/modals/modals.slice';
import { OfferBoxesSlice } from '../landing-pages/slices/offer-boxes/offer-boxes.slice';
import { PhoneNumberSlice } from '../landing-pages/slices/phone-number/phone-number.slice';
import { ProductDetailOverviewSlice } from '../landing-pages/slices/product-detail-overview/product-detail-overview.slice.class';
import { ProductsSlice } from '../landing-pages/slices/products/products.slice.class';
import { RedirectsSlice } from '../landing-pages/slices/redirects/redirects.slice';
import { SignUpFormSlice } from '../landing-pages/slices/signup-form/signup-form.slice';
import { TableData } from '../landing-pages/slices/table/table.model';
import { TableSlice } from '../landing-pages/slices/table/table.slice';
import { TariffTableSlice } from '../landing-pages/slices/tariff-table/tariff-table.slice';
import { AccordionItem } from '../shared/interfaces/accordian/accordian-item';
import CatalogResponse = ProductCatalogService.CatalogResponse;

@Injectable({
  providedIn: 'root',
})
export class PrismicService {
  private cms = inject(CmsService);
  private state = inject(StateService);
  private productsService = inject(ProductService);
  private browser = inject(BrowserService);
  private userService = inject(UserService);
  private _toasts: Subject<unknown> = new BehaviorSubject<unknown>(undefined);
  private currentLang: string;
  private url: string;
  public readonly onToastsLoaded: Observable<unknown> = this._toasts.asObservable();

  constructor() {
    this.init();
  }

  async init() {
    this.getToasts();
  }

  /**
   * Gets the translations for the toasts from prismic
   * Loads them into the translations cache
   */
  async getToasts() {
    const resp = await this.cms.getByType('toasts');
    const toasts = (resp[0]?.data?.toasts || []) as translationObj[];
    toasts.forEach(toast => (toast.type = 'toasts'));
    let translations = this.state.get<translationObj[]>(StorageKeys.Translations) || [];
    translations = translations.concat(toasts);
    this.state.set(StorageKeys.Translations, translations);
    this._toasts.complete();
  }

  /**
   * Returns a translated string from the translations cache based on a type and a key.
   * @param type The prismic document type
   * @param key The key value in the prismic document
   */
  getTranslation(type: string, key: string): string {
    const translations: translationObj[] = this.state.get(StorageKeys.Translations) as translationObj[];
    return translations.find((t: translationObj) => t.key === key && t.type === type)?.text || type + '.' + key;
  }

  /**
   * Converts raw prismic data into a LandingPage object.
   * This method automatically coverts all slices and fetches all linked documents in those slices
   * @param prismicData Raw data from prismic with the type new_landing_page
   */
  async convertLandingPage(prismicData: PrismicDocument): Promise<LandingPage> {
    this.currentLang = prismicData.lang.replace(/-ch|-gb/, '');
    const path = prismicData.uid;
    this.url = `${this.browser.getOrigin()}/${this.currentLang}/${path}`;
    const slices: SliceClass[] = await this.getSlice(prismicData);

    return new LandingPage(
      path,
      slices.flat(Infinity),
      prismicData.data.page_title,
      prismicData.data.meta_description,
      prismicData.data.hidden_from_search_engines
    );
  }

  async getSlice(prismicData: PrismicDocument) {
    return await Promise.all(
      prismicData.data.body
        .concat(prismicData.data.body1 ? prismicData.data.body1 : [])
        .map((sliceData: Slice) => {
          if (sliceData.slice_type === 'custom_content')
            return this.convertCustomContent(sliceData as NewLandingPageDocumentDataBodyCustomContentSlice);
          if (sliceData.slice_type === 'offer_boxes')
            return this.convertOfferBoxes(sliceData as NewLandingPageDocumentDataBodyOfferBoxesSlice);
          if (sliceData.slice_type === 'conditional_redirects')
            return this.convertRedirects(sliceData as LandingPageDocumentDataBodyRedirectSlice);
          if (sliceData.slice_type === 'products')
            return this.convertProducts(sliceData as NewLandingPageDocumentDataBodyProductsSlice);
          if (sliceData.slice_type === 'hero_area')
            return this.convertHeroArea(sliceData as NewLandingPageDocumentDataBodyHeroAreaSlice);
          if (sliceData.slice_type === 'phone_number_slice')
            return this.convertPhoneNumber(sliceData as NewLandingPageDocumentDataBodyPhoneNumberSliceSlice);
          if (sliceData.slice_type === 'benefits_list')
            return this.convertBenefitsList(sliceData as NewLandingPageDocumentDataBodyBenefitsListSlice);
          if (sliceData.slice_type === 'card_list')
            return this.convertCardList(sliceData as NewLandingPageDocumentDataBodyCardListSlice);
          if (sliceData.slice_type === 'line_check')
            return this.convertLineCheck(sliceData as NewLandingPageDocumentDataBodyLineCheckSlice);
          if (sliceData.slice_type === 'content_boxes')
            return this.convertContentBoxes(sliceData as NewLandingPageDocumentDataBodyContentBoxesSlice);
          if (sliceData.slice_type === 'image_text')
            return this.convertImageText(sliceData as NewLandingPageDocumentDataBodyImageTextSlice);
          if (sliceData.slice_type === 'centered_accordion')
            return this.convertCenteredAccordion(sliceData as NewLandingPageDocumentDataBodyCenteredAccordionSlice);
          if (sliceData.slice_type === 'modals')
            return this.convertModals(sliceData as NewLandingPageDocumentDataBody1ModalsSlice);
          if (sliceData.slice_type === 'tariff_table')
            return this.convertTariffTable(sliceData as NewLandingPageDocumentDataBodyTariffTableSlice);
          if (sliceData.slice_type === 'product_detail_overview')
            return this.convertProductDetailOverview(
              sliceData as NewLandingPageDocumentDataBodyProductDetailOverviewSlice
            );
          if (sliceData.slice_type === 'sign_up_form')
            return this.convertSignUpForm(sliceData as NewLandingPageDocumentDataBodySignUpFormSlice);
          if (sliceData.slice_type === 'landing_page_partial')
            return this.convertLandingPagePartial(sliceData as NewLandingPageDocumentDataBodyLandingPagePartialSlice);
          if (sliceData.slice_type === 'table_embed')
            return this.convertTable(sliceData as NewLandingPageDocumentDataBodyTableEmbedSlice);
          if (sliceData.slice_type === 'contest_form_embed')
            return this.convertContestForm(sliceData as NewLandingPageDocumentDataBodyContestFormEmbedSlice);
          console.error('Slice type not configured', sliceData.slice_type);
        })
        .filter((d: Slice) => d)
    );
  }

  /**
   * Converts the raw products slice data into a ProductsSlice object
   * @param sliceData Raw slice data with the type products
   */
  async convertProducts(sliceData: NewLandingPageDocumentDataBodyProductsSlice): Promise<ProductsSlice> {
    const productsFromPC: CatalogResponse[] = await this.productsService.getPCProducts().catch(() => null);
    const primary = sliceData.primary;
    const title: string = prismicToHtmlString(primary.title);
    const tabs: boolean = primary.tabs;

    const products: ProductListItem[] = [];

    for (const item of sliceData.items.filter((i: NewLandingPageDocumentDataBodyProductsSliceItem) => i.product)) {
      const featured: boolean = item.featured;
      const global: boolean = item.global;
      const local: boolean = item.local;
      const prepaid: boolean = item.prepaid;
      const all: boolean = item.all;

      let product: PCProduct;
      let promotion: Promotion;

      const prismicId = (item.product as FilledContentRelationshipField).id;
      const prismicDocument: ProductBoxDocument = (await this.cms.getById(prismicId)) as ProductBoxDocument;
      const planId = prismicDocument.data.product_code;
      if (productsFromPC) {
        const rateplan = productsFromPC.find(
          (product: ProductCatalogService.CatalogResponse) => product.code === planId
        );
        if (rateplan) {
          product = PCProduct.fromMS(rateplan, prismicDocument.data);
          promotion = await this.productsService.getCorrectPromotion(
            item.promotion as FilledContentRelationshipField,
            rateplan
          );
        }
      }
      if (product) {
        products.push({ product, promotion, featured, local, global, prepaid, all });
      }
    }

    products.sort(this.productsService.sortProductsByDiscount);

    return new ProductsSlice(sliceData, products, title, tabs);
  }

  /**

   * Converts the raw redirects slice data into a ProductDetailOverview object

   * @param sliceData Raw slice data with the type redirects

   */

  async convertProductDetailOverview(
    sliceData: NewLandingPageDocumentDataBodyProductDetailOverviewSlice
  ): Promise<ProductDetailOverviewSlice> {
    const productListItem = await this.productsService.convertProductBox(
      (await this.cms.getById((sliceData.primary.product as FilledContentRelationshipField).id)) as ProductBoxDocument,
      sliceData.primary.promotion as FilledContentRelationshipField
    );
    return new ProductDetailOverviewSlice(sliceData, productListItem);
  }

  /**
   * Converts the raw redirects slice data into a RedirectsSlice object
   * @param sliceData Raw slice data with the type redirects
   */
  async convertRedirects(sliceData: LandingPageDocumentDataBodyRedirectSlice): Promise<RedirectsSlice> {
    return new RedirectsSlice(sliceData);
  }

  /**
   * Converts the raw custom_content slice data into a CustomContentSlice object
   * @param sliceData Raw slice data with the type custom_content
   */
  async convertCustomContent(sliceData: NewLandingPageDocumentDataBodyCustomContentSlice): Promise<CustomContentSlice> {
    const primary = sliceData.primary;
    let string = prismicToHtmlString(primary.content);
    string = string.substring(5, string.length - 6);
    return new CustomContentSlice(sliceData, string);
  }

  /**
   * Converts the raw offer_boxes slice data into a OfferBoxesSlice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type offer_boxes
   */
  async convertOfferBoxes(sliceData: NewLandingPageDocumentDataBodyOfferBoxesSlice): Promise<OfferBoxesSlice> {
    const productsFromPC: CatalogResponse[] = await this.productsService.getPCProducts().catch(() => null);
    const primary = sliceData.primary;
    const items = sliceData.items;
    let offers: Offer[] = await Promise.all(
      items.map(async (d: NewLandingPageDocumentDataBodyOfferBoxesSliceItem) => {
        if ((d.offer as FilledContentRelationshipField)?.id) {
          const offerDocument = (await this.cms.getById(
            (d.offer as FilledContentRelationshipField).id
          )) as OfferDocument;
          return this.getOffer(offerDocument, productsFromPC, d.promotion as FilledContentRelationshipField);
        }
      })
    );
    offers = offers.filter((o: Offer | undefined) => o !== undefined);
    return new OfferBoxesSlice(
      sliceData,
      offers,
      primary.display_size,
      prismicToHtmlString(primary.title),
      primary.section_id
    );
  }

  /**
   * Converts the raw hero_offer slice data into a HeroAreaSlice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type offer_boxes
   */
  async convertHeroArea(sliceData: NewLandingPageDocumentDataBodyHeroAreaSlice) {
    const productsFromPC: CatalogResponse[] = await this.productsService.getPCProducts().catch(() => null);
    let offer: Offer;
    let secondaryOffer: Offer;
    const primary = sliceData.primary;
    if ((primary.offer as FilledContentRelationshipField).id) {
      const offerDocument = (await this.cms.getById(
        (primary.offer as FilledContentRelationshipField).id
      )) as OfferDocument;

      offer = await this.getOffer(offerDocument, productsFromPC, primary.promotion as FilledContentRelationshipField);
    }
    if (primary.secondary_offer && (primary.secondary_offer as FilledContentRelationshipField).id) {
      const offerDocument = (await this.cms.getById(
        (primary.secondary_offer as FilledContentRelationshipField).id
      )) as OfferDocument;
      offer = await this.getOffer(
        offerDocument,
        productsFromPC,
        primary.secondary_promotion as FilledContentRelationshipField
      );
    }
    return new HeroAreaSlice(
      sliceData,
      offer,
      secondaryOffer,
      primary.button_link,
      prismicToHtmlString(primary.title),
      prismicToHtmlString(primary.description)
    );
  }

  /**
   * Converts the raw phone_number slice data into a PhoneNumber Slice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type phone_number
   */
  async convertPhoneNumber(sliceData: NewLandingPageDocumentDataBodyPhoneNumberSliceSlice) {
    const primary = sliceData.primary;
    return new PhoneNumberSlice(
      sliceData,
      prismicToHtmlString(primary.phone_number),
      prismicToHtmlString(primary.description),
      primary.icon,
      primary.link,
      primary.alternative_text,
      primary.section_id
    );
  }

  /**
   * Converts the raw benefits_list slice data into a BenefitList Slice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type phone_number
   */
  async convertBenefitsList(sliceData: NewLandingPageDocumentDataBodyBenefitsListSlice): Promise<BenefitsListSlice> {
    const primary = sliceData.primary;
    const items = sliceData.items;
    return new BenefitsListSlice(
      sliceData,
      prismicToHtmlString(primary.title),
      primary.button_link,
      items.map(
        (item: NewLandingPageDocumentDataBodyBenefitsListSliceItem) =>
          new BenefitsListItem(item.icon, prismicToHtmlString(item.title), prismicToHtmlString(item.description))
      ),
      primary.section_id
    );
  }

  /**
   * Converts the raw card_list slice data into a CardList Slice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type card_list
   */
  async convertCardList(sliceData: NewLandingPageDocumentDataBodyCardListSlice) {
    const primary = sliceData.primary;
    const items = sliceData.items as NewLandingPageDocumentDataBodyCardListSliceItem[];
    return new CardListSlice(
      sliceData,
      prismicToHtmlString(primary.title),
      items.map(
        (item: NewLandingPageDocumentDataBodyCardListSliceItem) =>
          new CardListItem(
            item.icon,
            prismicToHtmlString(item.card_title),
            prismicToHtmlString(item.description),
            item.button_text,
            item.button_link
          )
      ),
      primary.section_id
    );
  }

  /**
   * Converts the raw line_check slice data into a LineCheck Slice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type line_check
   */
  async convertLineCheck(sliceData: NewLandingPageDocumentDataBodyLineCheckSlice) {
    const { items: lineCheckSliceItems } = sliceData;

    let lineCheckRepeatableItems: LineCheckRepeatableItem[] = [];

    if (lineCheckSliceItems) {
      lineCheckRepeatableItems = await Promise.all(
        lineCheckSliceItems
          .filter(({ product_box }) => !!(product_box as FilledContentRelationshipField).id)
          .map(
            async ({
              product_type__for_special_product_slice,
              redirect__for_special_product_slice,
              product_box,
              promotion,
              featured,
              global,
              local,
              prepaid,
            }) => {
              const [productCode, promotionDoc] = await Promise.all([
                (await this.cms.getById((product_box as FilledContentRelationshipField).id)).data
                  .product_code as string,
                (promotion as FilledContentRelationshipField).id
                  ? ((await this.cms.getById(
                      (promotion as FilledContentRelationshipField).id
                    )) as unknown as FilledContentRelationshipField)
                  : Promise.resolve(null),
              ]);

              return {
                productTypeForSpecialProductSlice: product_type__for_special_product_slice,
                redirectForSpecialProductSlice: redirect__for_special_product_slice,
                productCode,
                promotion: promotionDoc,
                featured,
                global,
                local,
                prepaid,
              };
            }
          )
      );
    }

    return new LineCheckSlice(sliceData, lineCheckRepeatableItems);
  }

  /**
   * Converts the raw content_boxes slice data into a ContentBoxesSlice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type content_boxes
   */
  async convertContentBoxes(sliceData: NewLandingPageDocumentDataBodyContentBoxesSlice) {
    const primary = sliceData.primary;
    const items = sliceData.items;
    return new ContentBoxesSlice(
      sliceData,
      prismicToHtmlString(primary.title),
      items.map((item: NewLandingPageDocumentDataBodyContentBoxesSliceItem) => {
        return new ContentBox(
          item.icon,
          prismicToHtmlString(item.title),
          prismicToHtmlString(item.text),
          item.button_text,
          item.button_link
        );
      }),
      primary.section_id
    );
  }

  /**
   * Converts the raw image_text slice data into a ImageTextSlice object
   * It fetches the offer details as well
   * @param sliceData Raw slice data with the type image_text
   */
  async convertImageText(sliceData: NewLandingPageDocumentDataBodyImageTextSlice) {
    const primary = sliceData.primary;
    const imageTextImage = primary.image;
    let images: ImageSet | undefined;
    // An image can be empty however Prismic seems to return an object anyway
    if (imageTextImage && imageTextImage.url && imageTextImage.dimensions) {
      images = new ImageSet(primary.image);
    }
    return new ImageTextSlice(
      sliceData,
      prismicToHtmlString(primary.title),
      prismicToHtmlString(primary.text),
      images,
      primary.button_link
    );
  }

  /**
   * Converts the raw centered_accordion slice data into a CenteredAccordionSlice object
   * @param sliceData Raw slice data with the type centered_accordion
   */
  async convertCenteredAccordion(sliceData: NewLandingPageDocumentDataBodyCenteredAccordionSlice) {
    const accordionItems: AccordionItem[] = [];
    const primary = sliceData.primary;
    const items = sliceData.items;
    for (const item of items) {
      accordionItems.push({
        title: prismicToHtmlString(item.title),
        content: prismicToHtmlString(item.text),
      });
    }
    return new CenteredAccordionSlice(sliceData, prismicToHtmlString(primary.title), accordionItems);
  }

  /**
   * Converts the raw tariff_table slice data into a TariffTableSlice object
   * @param sliceData Raw slice data with the type tariff_table
   */
  convertTariffTable(sliceData: NewLandingPageDocumentDataBodyTariffTableSlice): TariffTableSlice {
    return new TariffTableSlice(sliceData, this.url);
  }

  /**
   * Converts the raw signup_form slice data into a SignUpFormSlice object
   * @param sliceData Raw slice data with the type signup_form
   */
  convertSignUpForm(sliceData: NewLandingPageDocumentDataBodySignUpFormSlice): SignUpFormSlice {
    return new SignUpFormSlice(sliceData);
  }

  async getOffer(
    offerDocument: OfferDocument,
    productsFromPC: ProductCatalogService.CatalogResponse[],
    promotionRelationshipField: FilledContentRelationshipField
  ) {
    let offer: Offer;
    if (offerDocument.data.product_code) {
      const promotionFromCTASlice = this.getPromotionFromOfferCtaSlice(offerDocument);
      const productFromPC = productsFromPC
        ? productsFromPC.find(product => product.code === offerDocument.data.product_code)
        : undefined;
      const promotion = await this.productsService.getCorrectPromotion(
        promotionRelationshipField,
        productFromPC,
        promotionFromCTASlice
      );
      offer = this.convertOffer(offerDocument, productFromPC, promotion);
    } else {
      offer = this.convertOffer(offerDocument);
    }

    return offer;
  }

  /**
   * Converts the raw prismic offer response into an OfferSlice object
   * @param offerData Raw response of prismic for the document type offer
   * @param productsFromPC Products from the product catalog
   */
  convertOffer(offerData: OfferDocument, productFromPC?: CatalogResponse, promotion?: Promotion) {
    const content = prismicToHtmlString(offerData.data.offer_content);
    const offerImage = offerData.data.offer_image;
    let productListItem: ProductListItem;
    if (productFromPC) {
      const product = PCProduct.fromMS(productFromPC);
      productListItem = {
        product,
        promotion,
      };
    }
    let images: ImageSet | undefined;
    // An image can be empty however Prismic seems to return an object anyway
    if (offerImage && offerImage.url && offerImage.dimensions) {
      images = new ImageSet(offerImage);
    }
    const slices: SliceClass[] = offerData.data.body
      ?.map((sliceData: Slice) => {
        switch (sliceData.slice_type) {
          case 'offer_cta':
            return this.convertOfferCta(sliceData as OfferDocumentDataBodyOfferCtaSlice);
          case 'count_down':
            return this.convertOfferCountDown(sliceData as OfferDocumentDataBodyCountDownSlice);
          case 'promo_banner':
            return this.convertOfferSticker(sliceData as OfferDocumentDataBodySquircleSlice);
          default:
            console.error('Offer slice type is not configured', sliceData.slice_type);
        }
      })
      .filter((d: SliceClass) => d);

    return new Offer(offerData, content, images, productListItem, slices);
  }

  convertOfferCta(sliceData: OfferDocumentDataBodyOfferCtaSlice) {
    // temporary workaround for texts with language on beggining (our awards section for example)
    // check if the string starts with /en/ or /it/ or /de/ or /fr/
    // if so, replace it with empty string
    const sliceDataLink = sliceData.primary.button_link;
    // button_link is a text field, not a link field in Prismic.
    // So - best to create a document/web link variable using the text field.
    const buttonLink = createLinkFromString(sliceDataLink);

    return new OfferCtaSlice(sliceData, sliceData.primary.button_text, buttonLink, sliceData.primary.button_color);
  }

  convertOfferCountDown(sliceData: OfferDocumentDataBodyCountDownSlice) {
    if (!sliceData.items) {
      return;
    }

    // Countdowns can have multiple date times so we take the earliest one that isn't in the past.
    // Dates come as JSON strings in the format: 2022-01-01T23:00:00+0000
    // We can just use string comparisons for simplicity as
    // '2021-01-01T23:00:00+0000' < '2022-01-01T23:00:00+0000'

    // Create a list of date strings and sort it
    const end_dates: Array<string> = sliceData.items
      .map((v: OfferDocumentDataBodyCountDownSliceItem) => v.count_down_date as string)
      .sort();
    const now = new Date();

    for (const d of end_dates) {
      const countdown_date = new Date(d);
      if (countdown_date.getTime() > now.getTime()) {
        // Break out as soon as we have a date. The list is sorted so this will be the earliest
        return new OfferCountDownSlice(sliceData, d);
      }
    }
  }

  convertOfferSticker(sliceData: OfferDocumentDataBodySquircleSlice) {
    return new OfferStickerSlice(sliceData);
  }

  async convertFooter(prismicData: FooterDocumentDataBodyLinksAreaSlice[]): Promise<Footer> {
    // Temporary workaround. Not the prettiest solution but make it work for now
    const footerLinkSections: Array<FooterLinkSection> = [];
    for (const d of prismicData) {
      if (d.slice_type === 'links_area') {
        footerLinkSections.push(
          new FooterLinkSection(
            d.primary.title,
            d.items.map((l: FooterDocumentDataBodyLinksAreaSliceItem) => new FooterLink(l.link_title, l.link))
          )
        );
      }
    }

    const slices: SliceClass[] = await Promise.all(
      prismicData
        .map(
          (
            sliceData:
              | FooterDocumentDataBodyLinksAreaSlice
              | FooterDocumentDataBodyCtaAreaSlice
              | FooterDocumentDataBodyBottomLinksAreaSlice
          ) => {
            let footerBottomLinkSelections: Array<FooterBottomLinkSelection>;

            switch (sliceData.slice_type) {
              case 'cta_area':
                return new FooterCTAAreaSlice(
                  sliceData as FooterDocumentDataBodyCtaAreaSlice,
                  sliceData.items.map(
                    (i: FooterDocumentDataBodyCtaAreaSliceItem) =>
                      new FooterLinkItem(
                        prismicToHtmlString(i.title),
                        prismicToHtmlString(i.description),
                        i.icon,
                        i.link
                      )
                  )
                );
              case 'links_area':
                // Do nothing here as we build the links_area slice manually
                //   return new FooterLinksAreaSlice({slice_type: 'links_area', label: null}, footerLinkSections);
                break;
              case 'bottom_links_area':
                footerBottomLinkSelections = [];
                footerBottomLinkSelections.push(
                  new FooterBottomLinkSelection(
                    sliceData.items.map(
                      (l: FooterDocumentDataBodyBottomLinksAreaSliceItem) => new FooterLink(l.link_name, l.link)
                    )
                  )
                );
                return new FooterBottomLinksAreaSlice(sliceData, footerBottomLinkSelections);
              default:
                console.error('Slice type not configured', (sliceData as Slice).slice_type);
            }
          }
        )
        .filter((s: SliceClass) => s)
    );

    // Need to clean up the links area. Insert where they should have been
    const linksAreaIdx = prismicData.findIndex(s => s.slice_type === 'links_area');
    if (linksAreaIdx > -1) {
      slices.splice(
        linksAreaIdx,
        0,
        new FooterLinksAreaSlice(
          { slice_type: 'links_area', slice_label: null, primary: null, items: null, id: null },
          footerLinkSections
        )
      );
    }

    return new Footer(slices);
  }

  async convertModals(prismicData: NewLandingPageDocumentDataBody1ModalsSlice): Promise<ModalsSlice> {
    const modals: Modal[] = await Promise.all(
      prismicData.items.map(async (modal): Promise<Modal> => {
        if (!(modal.modal_ref as FilledContentRelationshipField).id) {
          return;
        }

        const doc = (await this.cms.getById(
          (modal.modal_ref as FilledContentRelationshipField).id
        )) as ModalKitsuneDocument;

        const imgDoc = doc.data.body.find(
          (data: Slice) => data.slice_type === 'image__kitsune_'
        ) as ModalKitsuneDocumentDataBodyImageKitsuneSlice;
        const contentDoc = doc.data.body.filter((data: Slice) => data.slice_type === 'custom_content');
        const linksDoc = doc.data.body.filter((data: Slice) => data.slice_type === 'link');

        const title = <RTTextNode>doc.data.modal_title[0];
        const subTitle = <RTTextNode>doc.data.modal_subtitle[0];

        return {
          id: doc.id,
          name: doc.data.modal_name,
          ...(title && {
            title: prismicToHtmlString(doc.data.modal_title),
          }),
          titleAndSubtitleAlignment: doc.data.title_and_subtitle_alignment,
          hide_to_logged_in_users: doc.data.hide_to_logged_in_users,
          hide_to_not_logged_in_users: doc.data.hide_to_not_logged_in_users,
          ...(subTitle && {
            subtitle: prismicToHtmlString(doc.data.modal_subtitle),
          }),
          ...(doc.data.modal_footer_text && {
            footerText: prismicToHtmlString(doc.data.modal_footer_text),
          }),
          ...(imgDoc?.primary.image && {
            img: new ImageSet(imgDoc.primary.image),
            imgStaticWidth: imgDoc.primary.static_width,
            imgAlignment: imgDoc.primary.alignment,
            imgSize: imgDoc.primary.size,
          }),
          links: linksDoc.map(link => link.primary as RawLink),
          content: contentDoc
            .map((doc: ModalKitsuneDocumentDataBodyCustomContentSlice) => {
              const primary = doc.primary;
              return prismicToHtmlString(primary.content as RichTextField);
            })
            .reduce((accDoc, nextDoc) => `${accDoc}${nextDoc}`, ''),
          showAutomaticallyAfter: modal.show_automatically_after,
          showOnPageExit: modal.show_on_page_exit,
          showOnDevices: modal.show_on_devices,
        };
      })
    );

    return new ModalsSlice(prismicData, modals.filter(Boolean));
  }

  async convertLandingPagePartial(
    prismicData: NewLandingPageDocumentDataBodyLandingPagePartialSlice
  ): Promise<unknown> {
    const partialDocId = (prismicData.primary.page_partial_reference as FilledContentRelationshipField).id;

    if (!partialDocId) {
      return prismicData;
    }

    const prismicState = new LoggedInStatus(prismicData.primary.logged_in_status);

    const loggedIn = await lastValueFrom(this.userService.isAuthenticated());
    const wasAuthenticated = !!this.userService.getPreviouslyLoggedInData();

    const currentStatuses = LoggedInStatus.findCurrentStatus(loggedIn, wasAuthenticated);

    const partial: PrismicDocument = await this.cms.getById(partialDocId);

    if (currentStatuses.includes(prismicState.loggedInStatus)) {
      return this.getSlice(partial);
    }

    return Promise.resolve([]);
  }

  async convertTable(prismicData: NewLandingPageDocumentDataBodyTableEmbedSlice): Promise<unknown> {
    const tableDocId = (prismicData.primary.table_document_ref as FilledContentRelationshipField).id;

    if (!tableDocId) {
      return prismicData;
    }

    const tableData: TableData = (await this.cms.getById(tableDocId)).data as TableData;

    tableData.title = typeof tableData.title === 'string' ? tableData.title : prismicToHtmlString(tableData.title);

    tableData.caption =
      typeof tableData.caption === 'string' ? tableData.caption : prismicToHtmlString(tableData.caption);

    tableData.columns = tableData.columns
      .map(({ column_id, column_icon, column_text, column_cta_text, column_cta_link, column_cta_theme }) => ({
        column_id,
        column_icon,
        column_text: typeof column_text === 'string' ? column_text : prismicToHtmlString(column_text),
        column_cta_text,
        column_cta_link,
        column_cta_theme,
      }))
      .filter((column, index, self) => self.findIndex(col => col.column_id === column.column_id) === index);

    tableData.rows = tableData.rows.map(
      ({ column_reference_id, row_icon, row_text, row_cta_text, row_cta_link, row_cta_theme }) => ({
        column_reference_id,
        row_icon,
        row_text: typeof row_text === 'string' ? row_text : prismicToHtmlString(row_text),
        row_cta_text,
        row_cta_link,
        row_cta_theme,
      })
    );

    return new TableSlice(prismicData, tableData);
  }

  async convertContestForm(prismicData: NewLandingPageDocumentDataBodyContestFormEmbedSlice): Promise<unknown> {
    const formDocId = (prismicData.primary.contest_form_document as FilledContentRelationshipField).id;

    if (!formDocId) {
      return prismicData;
    }

    const formData: ContestFormDocument = (await this.cms.getById(formDocId)) as ContestFormDocument;

    return new ContestFormSlice(prismicData, formData);
  }

  getPromotionFromOfferCtaSlice(offerDocument: OfferDocument) {
    const offerCta = offerDocument.data.body?.find((sliceData: Slice) => sliceData.slice_type === 'offer_cta');
    if (offerCta) {
      const {
        primary: { button_link },
      } = offerCta as OfferDocumentDataBodyOfferCtaSlice;
      return button_link?.includes('promotions')
        ? button_link.substring(button_link.indexOf('promotions')).replace('promotions=', '')
        : undefined;
    }
    return undefined;
  }
}
