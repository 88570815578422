import { ChangeDetectionStrategy, Component, input, TemplateRef, ViewChild } from '@angular/core';
import { Params, QueryParamsHandling } from '@angular/router';

export type MenuItemLink = {
  rawUrl?: URL | string;
  routerLink?: string | string[];
  queryParams?: Params;
  queryParamsHandling?: QueryParamsHandling;
  routerLinkActive?: string;
  fragment?: string | null;
};

@Component({
  selector: 'lib-menu-item',
  template: `
    <ng-template #template>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MenuItemComponent {
  menuItemLink = input<MenuItemLink>();
  value = input<unknown>();
  icon = input<string>();
  @ViewChild('template') template: TemplateRef<unknown>;
}
